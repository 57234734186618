<template>
	<div class="card">
		<SubTitle :title="'个人信息'"></SubTitle>
		<van-row>
			<van-col span="6">姓名</van-col>
			<van-col span="6">{{ customerData.customerName | dash }}</van-col>
			<van-col span="6">性别</van-col>
			<van-col span="6">{{ customerData.sex | dash }}</van-col>

			<van-col span="6">年龄</van-col>
			<van-col span="6">{{ customerData.age | dash }}</van-col>
			<van-col span="6">婚姻</van-col>
			<van-col span="6">{{ customerData.marriageName | dash }}</van-col>

			<van-col span="6">户籍地址</van-col>
			<van-col span="18" class="flex-between flex-ac">
				<van-tag
					class="fn-mr5"
					type="warning"
					size="mini"
					v-if="
						addrData.permanentzAddress &&
							addrData.permanentzAddress.available &&
							addrData.permanentzAddress.available !== 'ORIGINAL'
					"
					>{{ addrData.permanentzAddress.available | addressType }}</van-tag
				>
				<div class="long-text">
					{{ addrData.permanentzAddress.address | dash }}
				</div>
				<!-- <van-icon name="edit" @click="openPopup('户籍地址', {})" /> -->
				<van-button
					v-if="addrData.permanentzAddress.address"
					type="info"
					size="mini"
					@click="mapNavigation(addrData.permanentzAddress.address)"
					>导航</van-button
				>
			</van-col>

			<van-col span="6">居住地址</van-col>
			<van-col span="18" class="flex-between flex-ac">
				<van-tag
					class="fn-mr5"
					type="warning"
					size="mini"
					v-if="
						addrData.liveAddress &&
							addrData.liveAddress.available &&
							addrData.liveAddress.available !== 'ORIGINAL'
					"
					>{{ addrData.liveAddress.available | addressType }}</van-tag
				>
				<div class="long-text">{{ addrData.liveAddress.address | dash }}</div>
				<!-- <van-icon name="edit" @click="openPopup('居住地址', {})" /> -->
				<van-button
					v-if="addrData.liveAddress.address"
					type="info"
					size="mini"
					@click="mapNavigation(addrData.liveAddress.address)"
					>导航</van-button
				>
			</van-col>

			<van-col span="6">房产地址</van-col>
			<van-col span="18" class="flex-between flex-ac">
				<van-tag
					class="fn-mr5"
					type="warning"
					size="mini"
					v-if="
						addrData.fcAddr &&
							addrData.fcAddr.available &&
							addrData.fcAddr.available !== 'ORIGINAL'
					"
					>{{ addrData.fcAddr.available | addressType }}</van-tag
				>
				<div class="long-text">{{ addrData.fcAddr.address | dash }}</div>
				<!-- <van-icon name="edit" @click="openPopup('房产地址', {})" /> -->
				<van-button
					v-if="addrData.fcAddr.address"
					type="info"
					size="mini"
					@click="mapNavigation(addrData.fcAddr.address)"
					>导航</van-button
				>
			</van-col>

			<van-col span="6">单位地址</van-col>
			<van-col span="18" class="flex-between flex-ac">
				<van-tag
					class="fn-mr5"
					type="warning"
					size="mini"
					v-if="
						addrData.unitAddress &&
							addrData.unitAddress.available &&
							addrData.unitAddress.available !== 'ORIGINAL'
					"
					>{{ addrData.unitAddress.available | addressType }}</van-tag
				>
				<div class="long-text">{{ addrData.unitAddress.address | dash }}</div>
				<!-- <van-icon name="edit" @click="openPopup('单位地址', {})" /> -->
				<van-button
					v-if="addrData.unitAddress.address"
					type="info"
					size="mini"
					@click="mapNavigation(addrData.unitAddress.address)"
					>导航</van-button
				>
			</van-col>

			<van-col span="6">联系方式</van-col>
			<van-col span="18" class="flex-between flex-ac">
				<div>
					<a :href="`tel:${currentMobile}`">{{ currentMobile }}</a>
				</div>
				<van-icon
					v-if="!readonly"
					style="margin-left: 10px; padding: 5px;"
					name="edit"
					@click="openPopup('修复联系方式', customerData)"
				/>
			</van-col>
		</van-row>

		<SubTitle title="车辆信息">
			<template #right>
				<van-button type="info" size="mini" @click="goGpsList"
					>查看GPS信息</van-button
				>
			</template>
		</SubTitle>
		<van-row>
			<van-col span="6">车牌号</van-col>
			<van-col span="6">{{ customerData.carPlateNo | dash }}</van-col>
			<van-col span="6">GPS情况</van-col>
			<van-col span="6">
				{{ gpsData.warningTypeName | dash }}
			</van-col>

			<van-col span="6">是否收车</van-col>
			<van-col span="6">{{ customerData.leaseStatus | dash }}</van-col>
			<van-col span="6">法诉</van-col>
			<van-col span="6">{{ customerData.legalStatus | dash }}</van-col>

			<!-- <van-col span="6">车主名称</van-col>
      <van-col span="6">{{ customerData.carOwner | dash }}</van-col> -->
			<van-col span="6">清收状态</van-col>
			<van-col span="6">{{ customerData.caseStatusStr | dash }}</van-col>
			<van-col span="6">商户收车</van-col>
			<van-col span="6">{{
				customerData.merchantCollectStatusStr | dash
			}}</van-col>
			<van-col span="6">是否投诉客户</van-col>
			<van-col span="18">{{
				customerData.complaintReason ? customerData.complaintReason : "否"
			}}</van-col>

			<van-col span="6">车型</van-col>
			<van-col span="18">{{
				customerData.vehicleModelFullName | dash
			}}</van-col>
		</van-row>

		<SubTitle title="租后信息"> </SubTitle>
		<van-row>
			<van-col span="6">产品</van-col>
			<van-col span="18">{{ customerData.productName | dash }}</van-col>
			<van-col span="6">融资金额</van-col>
			<van-col span="4">{{ customerLoanData.amount | dash }}</van-col>
			<van-col span="8">融资时间</van-col>
			<van-col span="6">{{ customerLoanData.loanDate | dash }}</van-col>

			<van-col span="6">还款期数</van-col>
			<van-col span="4"
				>{{ customerLoanData.payedNo | dash }}/{{
					customerLoanData.no | dash
				}}</van-col
			>
			<van-col span="8">逾期起日</van-col>
			<van-col span="6">{{ customerLoanData.overdueDate | dash }}</van-col>

			<van-col span="6">逾期天数</van-col>
			<van-col span="4">{{ customerLoanData.overdueDay | dash }}</van-col>
			<van-col span="8">逾期期数/月还款</van-col>
			<van-col span="6">
				{{ customerLoanData.overdueNo | dash }}/{{
					customerLoanData.monthPayedAmount | dash
				}}
			</van-col>
		</van-row>

		<SubTitle title="当前逾期"></SubTitle>
		<van-row>
			<van-col span="6">逾期合计</van-col>
			<van-col span="18">{{ loanOverdueData.total | dash }}</van-col>

			<van-col span="6">逾期租金</van-col>
			<van-col span="6">{{ loanOverdueData.rentRemain | dash }}</van-col>
			<van-col span="6">
				逾期违约金
			</van-col>
			<van-col span="6">{{ loanOverdueData.interestRemain | dash }}</van-col>
		</van-row>

		<SubTitle title="结清试算">
			<template #right>
				<van-button
					type="info"
					v-if="!readonly"
					size="mini"
					@click="openPopup('结清试算', {})"
					>点击试算</van-button
				>
			</template>
		</SubTitle>
		<van-row v-for="(item, index) in repayList" :key="index">
			<van-col span="6">结清金额</van-col>
			<van-col span="6">{{
				(item.trialResult && item.trialResult.repayAdvanceAmt) | dash
			}}</van-col>
			<van-col span="5">
				试算时间
			</van-col>
			<van-col span="7">{{
				item.createTime | dateFormat("YYYY-MM-DD") | dash
			}}</van-col>
		</van-row>
		<van-empty
			style="background-color: #fff;padding: 0.5rem 0;"
			image-size="40px"
			v-if="!repayList.length"
			description="暂无试算记录"
		/>

		<template v-if="bankData.bankAccount">
			<SubTitle title="回款账户"></SubTitle>
			<van-row>
				<van-col span="6">回款户名</van-col>
				<van-col span="18">{{ bankData.bankName | dash }}</van-col>
				<van-col span="6">账号</van-col>
				<van-col span="18"
					>{{ bankData.bankAccount | dash
					}}<van-icon
						v-if="!bankData.isSeen"
						class="fn-ml5"
						name="eye-o"
						@click="sensitiveInfo('bank', bankData.bankAccount)"
				/></van-col>
				<van-col span="6">银行</van-col>
				<van-col span="18">{{ bankData.openAccountBank | dash }}</van-col>
			</van-row>
		</template>

		<SubTitle title="联系人信息">
			<template #right>
				<van-button
					type="info"
					size="mini"
					v-if="!readonly"
					@click="openPopup('新增联系人', {})"
					>新增联系人</van-button
				>
			</template>
		</SubTitle>

		<div class="table" v-if="relatePersonData" style="margin-top: 0;">
			<div class="row title">
				<span>联系人姓名</span><span>联系人手机号</span><span>关系</span>
			</div>
			<div
				class="row"
				v-for="(contact, index) in relatePersonData"
				:key="index"
			>
				<span>{{ contact.contactName }}</span>
				<span>
					<span v-if="!contact.isSeen">{{ contact.mobile }}</span>
					<a v-else :href="`tel://${contact.mobile}`">{{ contact.mobile }}</a>
					<van-icon
						v-if="!contact.isSeen"
						class="fn-ml5"
						name="eye-o"
						@click="sensitiveInfo('mobile', contact.mobile, index)"
				/></span>
				<span>
					{{ contact.contactRelationStr | dash }}
					<van-icon
						v-if="!readonly"
						style="margin-left: 10px; padding: 5px;"
						name="edit"
						@click="openPopup('编辑联系人', contact, true)"
					/>
				</span>
			</div>
		</div>

		<SubTitle title="电核记录"></SubTitle>
		<van-row
			v-for="(item, index) in phoneVerify"
			:key="index"
			style="margin-bottom: 5px;"
		>
			<van-col span="6">姓名</van-col>
			<van-col span="6">{{ item.phoneVerificationItem | dash }}</van-col>
			<van-col span="6">关系</van-col>
			<van-col span="6">
				{{ item.phoneVerificationRelationStr | dash }}
			</van-col>
			<van-col span="6">电核内容</van-col>
			<van-col span="18" class="flex-between flex-ac" style="text-align: left;">
				<div class="long-text">{{ item.phoneVerificationContent | dash }}</div>
			</van-col>
		</van-row>
		<van-empty
			style="background-color: #fff;padding: 0.5rem 0;"
			image-size="40px"
			v-if="!phoneVerify.length"
			description="暂无电核记录"
		/>

		<PopupSlot
			v-model="showPopup"
			:actionType="actionType"
			:actionItem="actionItem"
			@closePopup="closePopup"
			@confirmPopup="confirmPopup"
		>
			<van-form ref="refForm">
				<van-field
					v-if="['结清试算'].includes(actionType)"
					readonly
					required
					clickable
					:label="'结清日期：'"
					:rules="[{ required: true }]"
					:value="form.valueDate"
					placeholder="请选择日期"
					@click="showPicker = true"
				/>

				<template v-if="['新增联系人'].includes(actionType)">
					<van-field
						:readonly="['0'].includes(form.contactRelation)"
						required
						type="text"
						label="联系人："
						:rules="[{ required: true }]"
						v-model="form.contactName"
						placeholder="请输入联系姓名"
					/>
					<van-field
						required
						name="type"
						type="number"
						maxlength="11"
						label="联系号码："
						:rules="[{ required: true }]"
						placeholder="输入联系号码"
						v-model="form.contactPhone"
					>
					</van-field>
					<van-field
						required
						name="type"
						placeholder="请选择"
						label="联系人关系："
						:rules="[{ required: true }]"
						:value="form.contactRelationStr"
						readonly
						@click="showPicker = true"
					>
					</van-field>

					<van-field
						required
						name="callRecords"
						label="证明材料："
						:rules="[{ required: true, message: '请选择' }]"
						class="form-no-boder"
					>
						<template #input>
							<Uploader
								accept="image/*,video/*,audio/*"
								multiple
								v-model="form.callRecords"
							/>
						</template>
					</van-field>
					<div class="fn-fs1">
						注：上传通话录音、图片或者视频证明为联系人信息
					</div>
				</template>

				<template v-if="['修复联系方式', '编辑联系人'].includes(actionType)">
					<van-field
						readonly
						type="text"
						label="客户联系方式："
						:rules="[{ required: true }]"
						v-model="form.contactInformation"
						placeholder="请输入联系方式"
					/>
					<van-field
						required
						:label="`新联系号码：`"
						type="number"
						maxlength="11"
						:rules="[{ required: true }]"
						v-model="form.contactPhone"
						:placeholder="`请输入新联系号码`"
					>
					</van-field>
					<van-field
						required
						name="callRecords"
						label="通话记录："
						:rules="[{ required: true, message: '请选择' }]"
						class="form-no-boder"
					>
						<template #input>
							<Uploader
								accept="image/*,video/*,audio/*"
								multiple
								v-model="form.callRecords"
							/>
						</template>
					</van-field>
					<div class="fn-fs1">
						注：上传通话录音、图片或者视频证明手机号为联系人的
					</div>
				</template>

				<template
					v-if="
						['户籍地址', '居住地址', '房产地址', '单位地址'].includes(
							actionType
						)
					"
				>
					<van-field
						:label="`${actionType}：`"
						v-model="form.address"
						:placeholder="`请输入${actionType}`"
					/>
					<van-field name="addressType" label="" class="form-no-boder">
						<template #input>
							<van-radio-group
								v-model="form.addressType"
								direction="horizontal"
							>
								<van-radio name="1">地址有效</van-radio>
								<van-radio name="2">地址无效</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field
						required
						:label="`新${actionType}：`"
						v-model="form.newAddress"
						:placeholder="`请输入新${actionType}`"
					>
						<template #button>
							<van-button size="mini" type="info">获取定位</van-button>
						</template>
					</van-field>
					<van-field
						name="addressAttachList"
						label="地址照片："
						class="form-no-boder"
					>
						<template #input>
							<Uploader accept="image/*" v-model="form.addressAttachList" />
						</template>
					</van-field>
				</template>

				<van-popup v-model="showPicker" round position="bottom">
					<van-picker
						v-if="['新增联系人', '编辑联系人'].includes(actionType)"
						show-toolbar
						:columns="listRelation"
						@cancel="showPicker = false"
						@confirm="onConfirmRelation"
					/>
					<van-datetime-picker
						v-if="['结清试算'].includes(actionType)"
						v-model="currentDate"
						type="date"
						title="选择年月日"
						:min-date="minDate"
						:max-date="maxDate"
						@cancel="showPicker = false"
						@confirm="onConfirmDate"
					/>
				</van-popup>
			</van-form>
		</PopupSlot>
	</div>
</template>

<script>
import dayjs from "dayjs";
import * as _ from "lodash";
import { Toast } from "vant";
import ApiDetail from "@/api/caseDetail";
// import { goMapName } from "@/libs/utils";
import Api from "@/api/caseHandle";
import { sensitiveField } from "@/filters/filter";

const DEFAULT_FORM = {
	valueDate: "",
	phone: "",
	phoneType: "",
	contactPhone: "",
	callRecords: [],
	address: "",
	addressType: "",
	newAddress: "",
	addressAttachList: [],
	contactInformation: "",
	contactRelation: "",
	contactRelationStr: ""
};

export default {
	props: {
		detailInfo: {
			type: Object,
			required: true
		},
		bizNo: {
			type: String,
			required: true
		},
		readonly: {
			type: Boolean,
			required: false
		},
		addrData: {
			type: Object,
			required: true
		}
	},
	filters: {
		addressType(value) {
			let data = {
				EFFECTIVE: "有效",
				ORIGINAL: "原始",
				NEWLY: "新",
				INVALID: "无效"
			};
			return data[value] || "";
		}
	},
	data() {
		return {
			customerData: {},
			gpsData: {},
			customerLoanData: {},
			loanOverdueData: {},
			bankData: {},
			relatePersonData: [],
			loading: false,
			actionType: "",
			actionItem: {},
			showPopup: false,
			form: {
				..._.cloneDeep(DEFAULT_FORM)
			},
			showPicker: false,
			minDate: new Date(),
			maxDate: new Date(2100, 12, 30),
			currentDate: new Date(),
			orderInfo: {},
			repayList: [],
			phoneVerify: [] // 电催信息
		};
	},
	computed: {
		listRelation() {
			return (this.getInstancyRelativeEnum || []).map((e) => ({
				...e,
				text: e.displayName
			}));
		},
		selfRelation() {
			return this.relatePersonData.find(
				(i) => i.contactRelation == "0" && i.refId
			);
		},
		currentMobile() {
			return this.selfRelation
				? this.selfRelation.contactPhone
				: this.customerData.mobile || "-";
		}
	},
	async mounted() {
		// this.findByBizNo();
		await this.customerInfo();
		this.gpsStatus();
		this.customerLoanInfo();
		this.loanOverdueInfo();
		this.getPhoneVerify();
		this.bankInfo();
		this.relatePersonInfo();
		this.repayFindPage();
		this.fetchEnums("getInstancyRelativeEnum");
	},
	methods: {
		// 结清试算记录
		repayFindPage() {
			Api.repayFindPage({
				bizNo: this.bizNo,
				pageNo: 1,
				pageSize: 100
			}).then(({ data }) => {
				this.repayList = data.list
					.map((item) => ({
						...item,
						trialResult: JSON.parse(item.trialResult)
					}))
					.filter(
						(i) =>
							new Date(this.formatDate(i.trialResult.orderEndTime)) >=
							new Date(this.getCurrentTime())
					);
			});
		},
		formatDate(dateStr) {
			// 检查是否是完整的日期时间格式
			if (dateStr.includes(" ")) {
				dateStr = dateStr.split(" ")[0]; // 取日期部分
			}

			// 将'YYYYMMDD'格式转换为'YYYY-MM-DD'
			return dateStr.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
		},
		getCurrentTime() {
			const today = new Date();
			const year = today.getFullYear();
			const month = String(today.getMonth() + 1).padStart(2, "0"); // 月份从0开始计算，所以加1
			const day = String(today.getDate()).padStart(2, "0");
			const formattedDate = `${year}-${month}-${day}`; // 格式化为 YYYY-MM-DD
			return formattedDate;
		},
		// 查询借据详情
		findByBizNo() {
			Api.findByBizNo(this.bizNo).then((res) => {
				this.orderInfo = res.data || {};
			});
		},
		// 客户基本信息
		async customerInfo() {
			try {
				const res = await ApiDetail.customerInfo({ bizNo: this.bizNo });
				this.customerData = res.data || {};
			} catch (error) {
				Toast.fail(error);
			}
		},

		// 客户GPS状态相关信息
		async gpsStatus() {
			try {
				const res = await ApiDetail.gpsStatus({ bizNo: this.bizNo });
				this.gpsData = res.data || {};
			} catch (error) {
				Toast.fail(error);
			}
		},

		// 客户还款信息
		async customerLoanInfo() {
			try {
				const res = await ApiDetail.customerLoanInfo({ bizNo: this.bizNo });
				this.customerLoanData = res.data || {};
			} catch (error) {
				Toast.fail(error);
			}
		},

		// 客户还款信息
		async loanOverdueInfo() {
			try {
				const res = await ApiDetail.loanOverdueInfo({ bizNo: this.bizNo });
				this.loanOverdueData = res.data || {};
			} catch (error) {
				Toast.fail(error);
			}
		},

		// 案件回款账户信息
		async bankInfo() {
			try {
				const res = await ApiDetail.bankInfo({ bizNo: this.bizNo });
				this.bankData = res.data || {};
			} catch (error) {
				Toast.fail(error);
			}
		},

		// 案件用户联系人信息
		async relatePersonInfo() {
			try {
				const res = await ApiDetail.getCollectionContactPersonByCustomerId({
					customerId: this.customerData.customerNo
				});
				this.relatePersonData = (res.data || []).map((item) => ({
					...item,
					mobile: sensitiveField(item.contactPhone)
				}));
			} catch (error) {
				Toast.fail(error);
			}
		},

		// 敏感信息查询
		async sensitiveInfo(source = "mobile", ext, index) {
			try {
				const res = await ApiDetail.sensitiveInfo({
					bizNo: this.bizNo,
					source,
					ext
				});
				if (res.success) {
					if (source == "bank") {
						this.$set(this.bankData, "bankAccount", res.data);
						this.$set(this.bankData, "isSeen", true);
					}
					if (source == "mobile") {
						let mobile = this.relatePersonData[index].contactPhone;
						this.$set(
							this.relatePersonData[index],
							"mobile",
							res.data ? res.data : mobile
						);
						this.$set(this.relatePersonData[index], "isSeen", true);
					}
				}
			} catch (error) {
				Toast.fail(error);
			}
		},

		// 客户基本信息
		async getPhoneVerify() {
			try {
				const res = await ApiDetail.getPhoneVerify(this.bizNo);
				this.phoneVerify = res.data || [];
			} catch (error) {
				Toast.fail(error);
			}
		},

		goGpsList() {
			this.$router.push({
				name: "GpsList",
				query: { bizNo: this.bizNo }
			});
		},

		async mapNavigation(address) {
			// this.goMapName('重庆化龙桥地铁站', 2)
			// goMapName(address, 2);
			try {
				this.$loading();
				await this.$copy(address);
				this.$loading.close();
				Toast.success("地址已复制，请打开手机地图app粘贴导航");
			} catch (error) {
				Toast.fail(error);
			}
		},

		// 弹出层打开
		async openPopup(action, item, edit = false) {
			this.actionType = action;
			this.actionItem = { ...item };
			this.form = {
				...item,
				contactInformation:
					(item.userName || item.customerName || item.contactName) +
					"-" +
					(edit ? item.mobile : this.currentMobile),
				callRecords: item.callRecords?.split(",") || [],
				contactPhone: ""
			};
			this.showPopup = true;
		},

		// 关闭弹出层
		closePopup() {
			this.$set(this, "form", { ...DEFAULT_FORM });
		},
		// 弹出层确认
		async confirmPopup() {
			try {
				await this.$refs["refForm"].validate();
				let fn = {
					结清试算: this.settlementTrialCalculation,
					新增联系人: this.conContactfirmPopup,
					编辑联系人: this.conContactfirmPopup,
					修复联系方式: this.repairContact
				};
				fn[this.actionType]();
			} catch (error) {
				console.log("error :>> ", error);
			}
		},

		// 修复联系方式(如果this.selfRelation存在则是修复是否则新增)
		async repairContact() {
			try {
				let data = {};
				let callRecords = this.form.callRecords.join(",");
				if (this.selfRelation) {
					data = {
						...this.selfRelation,
						contactPhone: this.form.contactPhone,
						callRecords
					};
				} else {
					data = {
						contactName: this.customerData.customerName,
						contactPhone: this.form.contactPhone,
						contactRelation: "0",
						customerId: this.customerData.customerNo,
						refId: this.customerData.customerNo,
						callRecords
					};
				}
				if (this.loading) return;
				let { form } = this;
				if (!form.contactPhone) throw "请输入新联系号码";
				if (!/^(?:(?:\+|00)86)?1\d{10}$/.test(form.contactPhone))
					throw `请输入正确的新联系号码`;
				if (!callRecords || !callRecords.length) throw "请至少上传一个通话记录";
				this.loading = true;
				await (data.rowId
					? Api.fixContactPerson
					: Api.addCollectionContactPerson)(data);
				this.relatePersonInfo(); // 更新联系人列表
				this.showPopup = false;
			} catch (error) {
				this.$toast.fail(error);
			} finally {
				this.loading = false;
			}
		},

		// 新增、编辑联系人
		async conContactfirmPopup() {
			try {
				let { form } = this;
				if (!form.contactName) throw "请输入联系人";
				if (!form.contactPhone)
					throw this.form.rowId ? "请输入新联系号码" : "请输入联系号码";
				if (!/^(?:(?:\+|00)86)?1\d{10}$/.test(form.contactPhone))
					throw `请输入正确的新联系号码`;
				if (!form.contactRelation) throw "请选择联系人关系";
				let callRecords = this.form.callRecords.join(",");
				if (!callRecords || !callRecords.length) throw "请至少上传一个通话记录";
				if (this.loading) return;
				this.loading = true;
				let obj = {};
				if (this.form.rowId) {
					obj = {
						rowId: this.form.rowId,
						source: this.form.source,
						refId: this.form.refId
					};
				} else obj = {};

				let data = {
					contactName: this.form.contactName,
					contactPhone: this.form.contactPhone,
					contactRelation: this.form.contactRelation,
					customerId: this.customerData.customerNo,
					callRecords,
					...obj
				};
				await (this.form.rowId
					? Api.fixContactPerson
					: Api.addCollectionContactPerson)(data);
				this.relatePersonInfo(); // 更新联系人列表
				this.showPopup = false;
			} catch (error) {
				this.$toast.fail(error);
			} finally {
				this.loading = false;
			}
		},
		// 结清试算
		async settlementTrialCalculation() {
			try {
				let { form } = this;
				if (!form.valueDate) throw "请选择结清日期";
				if (this.loading) return;
				let { resOperatorDTO = {} } = JSON.parse(
					localStorage.getItem("userAccount") || "{}"
				);
				let { name = "-", mobile = "-" } = resOperatorDTO;
				this.loading = true;
				// let {
				//   capitalPlatformCode,
				//   capitalIouCode,
				//   memberNo,
				// } = this.orderInfo;

				let {
					customerNo: memberNo,
					capitalIouNo: capitalIouCode,
					capitalNo: capitalPlatformCode
				} = this.customerData;

				let data = {
					bizNo: this.bizNo,
					createBy: `${name}-${mobile}`,
					planRepayDate: form.valueDate,
					platform: capitalPlatformCode,
					capitalIouNo: capitalIouCode,
					customerNo: memberNo,
					repayType: "ADVANCE_SETTLE"
				};
				await Api.repayCalculate(data);
				this.repayFindPage();
				this.showPopup = false;
			} catch (error) {
				this.$toast.fail(error);
			} finally {
				this.loading = false;
			}
		},
		onConfirmDate(value) {
			this.$set(this.form, "valueDate", dayjs(value).format("YYYY-MM-DD"));
			this.showPicker = false;
		},
		onConfirmRelation(obj) {
			if (["0"].includes(obj.value)) {
				this.$set(this.form, "contactName", this.customerData.customerName);
			}
			this.$set(this.form, "contactRelation", obj.value);
			this.$set(this.form, "contactRelationStr", obj.text);
			this.showPicker = false;
		}
	}
};
</script>
<style lang="less" scoped>
.card {
	padding: 8px 8px 20px 8px;

	/deep/ .van-row {
		background: #fff;
		color: #333333;
		font-size: 14px;
		// border-left: 1px solid #333333;
		// border-top: 1px solid #333333;
		border-right: 1px solid #333333;
		border-bottom: 1px solid #333333;
		position: relative;

		display: flex;
		flex-wrap: wrap;
		&::before {
			content: "";
			display: block;
			width: 0;
			height: 100%;
			border-left: 1px solid #333333;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1000;
		}

		&::after {
			content: "";
			display: block;
			height: 0;
			clear: both;
			visibility: hidden;
		}

		.van-col {
			font-size: 12px;
			padding: 4px;
			text-align: center;
			line-height: 24px;
			// border-right: 1px solid #333333;
			// border-bottom: 1px solid #333333;
			border-left: 1px solid #333333;
			border-top: 1px solid #333333;
			// overflow: hidden;
			// text-overflow: ellipsis;
			// white-space: nowrap;

			&.flex-between > div {
				flex: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				&.long-text {
					white-space: normal;
				}
			}
		}
	}

	.table {
		margin-top: 10px;
		box-sizing: border-box;
		border: 1px solid #eaecf2;
		border-radius: 2px;

		.row {
			position: relative;
			background: #fff;
			flex-direction: row;
			justify-content: space-between;
			font-size: 14px;
			display: flex;
			align-items: center;
			padding: 15px 8px;

			&.title {
				font-weight: 650;
			}

			span {
				flex: 1;
				text-align: center;
				// &:first-child {
				//   text-align: left;
				// }
				// &:last-child {
				//   text-align: right;
				// }

				a {
					font-size: 12px;
					color: #3c86ff;
					letter-spacing: 0;
					text-align: justify;
					text-decoration: underline;
				}
			}

			&::after {
				content: " ";
				position: absolute;
				bottom: 0px;
				height: 1px;
				left: 0;
				right: 0;
				background: #eaecf2;
			}

			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}
}
</style>
